import { FC, useCallback, useState, type PropsWithChildren } from 'react';
import styled from 'styled-components';
import { UserIcon } from '..';
import ClapIconDefault from 'src/assets/clap_icon_default.svg';
import ClapIconActive from 'src/assets/clap_icon_active.svg';
import TipArrow from 'src/assets/tip_arrow.svg';
import Image from 'next/image';
import {
  WebCardSchema,
  WebCardsSchema,
  WebMeSchema,
} from 'src/__generated__/@types';
import Link from 'next/link';
import { ClappedEmployeeList } from './ClappedEmployeeList';
import { format } from 'date-fns';
import { api } from '@lib/api';
import { notifyError } from '@lib/notification';
import axios from 'axios';
import { KeyedMutator } from 'swr/dist/types';

type Props = WebCardSchema & {
  cardsMutate: KeyedMutator<
    {
      cards: WebCardsSchema;
      has_next: boolean;
    }[]
  >;
  currentEmployeeMutate: KeyedMutator<WebMeSchema>;
  currentEmployeeId: WebMeSchema['id'];
};

const Container = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.base.bg.main};
  border: 1px solid ${({ theme }) => theme.colors.base.border.main};
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
`;

const CardHeader = styled.div`
  display: grid;
  /* 左からそれぞれ, 送った従業員のアイコン, 送った従業員の名前, 矢印, 貰った従業員のアイコン, 貰った従業員の名前 の幅 */
  grid-template-columns: 40px 0.8fr 40px 64px 1fr;
  max-width: 100%;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const DisplayName = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

const SendPoint = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.main};
`;

const NameAndPoint = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Plus = styled.span`
  font-size: 12px;
`;

const Arrow = styled.div`
  display: grid;
  place-content: center;
`;

const Message = styled.p`
  font-size: 16px;
  margin-bottom: 16px;
  word-break: break-word;
`;

const DateAndEditWrapper = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
`;

const SendDate = styled.span`
  color: ${({ theme }) => theme.colors.base.text.sub};
  white-space: nowrap;
  flex-shrink: 0;
`;

const EditButton = styled(Link)`
  color: ${({ theme }) => theme.colors.base.text.sub};

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.base.text.main};
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
`;

const ClapWrapper = styled.div`
  display: grid;
  grid-template: auto auto / auto auto;
  align-items: end;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template: auto / auto;
  }
`;

const TooltipWrap = styled.div`
  position: relative;
  opacity: 0;
  transition: 0.2s ease-in;
  max-width: 120px;
  .text {
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    display: inline-block;
    white-space: nowrap;
    padding: 4px 8px;
    font-size: 0.8em;
    color: #fff;
    background: rgba(51, 51, 51, 0.8);
    border-radius: 3px;
    &:before {
      content: '';
      position: absolute;
      top: -14px;
      left: 50%;
      margin-left: -7px;
      border: 7px solid transparent;
      border-bottom: 7px solid rgba(51, 51, 51, 0.8);
    }
  }
`;
const Tooltip = (props: PropsWithChildren) => {
  return (
    <TooltipWrap>
      <span className="text">{props.children}</span>
    </TooltipWrap>
  );
};

const ClapButton = styled.button`
  display: grid;
  place-content: center;
  grid-column: 2;
  grid-row: 1 / -1;
  background-color: ${({ theme }) => theme.colors.primary.main};
  box-shadow: ${({ theme }) => theme.shadow.primary.main};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
    box-shadow: ${({ theme }) => theme.shadow.primary.hover};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primary.main};
    box-shadow: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.base.border.main};
    box-shadow: none;
  }

  &:hover ${TooltipWrap} {
    opacity: 1;
  }
`;

const ClapCount = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.main};
  text-align: right;
`;

const HashTag = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.main};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const formatMessageWithHashTag = (message: string) => {
  const hashTagRegExp = /#[^\s#]*/g;
  const hashTags: string[] = message.match(hashTagRegExp) || [];
  if (hashTags.length === 0) {
    return message;
  }
  const messages: string[] = message.split(hashTagRegExp);
  // message[0], hashTags[0], ... message[length-1] のような構成にする
  const elements: JSX.Element[] = hashTags.map((hashTag, i) => {
    return (
      <>
        {messages[i]}
        <HashTag href={`/cards/tagged?tag=${hashTag.slice(1)}`}>
          {hashTag}
        </HashTag>
      </>
    );
  });
  // 余った最後の要素を追加する
  elements.push(<>{messages[messages.length - 1]}</>);
  return elements;
};

const MessageComponent: FC<{ message: string }> = ({ message }) => {
  const messageText = useCallback(
    () => formatMessageWithHashTag(message),
    [message]
  );
  return <Message>{messageText()}</Message>;
};

/**
 * 1投稿あたりのコンポーネント
 * @param cardsMutate -
 *  拍手時に投稿一覧を更新する関数を
 *  CardList の useSWRInfinite から受け取る
 * @param currentEmployeeMutate -
 *  拍手時にログインユーザー情報 (送れるポイント) を更新する関数を
 *  useCurrentEmployee から受け取る
 */
export const Card: FC<Props> = ({
  id,
  from_employee,
  to_employee,
  amount,
  message,
  is_editable,
  clap_count,
  claps,
  created_at,
  cardsMutate,
  currentEmployeeMutate,
  currentEmployeeId,
}) => {
  const sendDate = format(new Date(created_at), 'MM/dd HH:mm');

  const handleClickClapButton = useCallback(async () => {
    try {
      // 拍手は連打での使用頻度が高いため成功時の通知 (notifySuccess) は煩わしいので行わない
      await api.clap(to_employee.tenant_id, id);
      // 拍手数のみを更新するエンドポイントがないため、投稿一覧を再フェッチして拍手数を更新する
      // TODO: 拍手数単体を取得するエンドポイントを実装したらそれの mutate に置き換える
      cardsMutate();
      // ログインユーザー情報を再フェッチしてヘッダーに表示している送れるポイント数を更新する
      currentEmployeeMutate();
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notifyError(e.response?.data.message);
      } else {
        notifyError('拍手は失敗しました');
      }
    }
  }, [to_employee.tenant_id, id, cardsMutate, currentEmployeeMutate]);

  // onMouseDown / onMouseUp で next/image の拍手アイコン画像のソースを切り替える
  const [isClapped, setIsClapped] = useState(false);
  const handleMouseDownClapButton = useCallback(() => setIsClapped(true), []);
  const handleMouseUpClapButton = useCallback(() => setIsClapped(false), []);

  // 拍手ができない条件の時に ClapButton を disabled にする
  const isClappedOver20 =
    claps?.filter(({ employee: { id } }) => currentEmployeeId === id)[0]
      ?.amount === 20;
  const isMyCard =
    currentEmployeeId === from_employee.id ||
    currentEmployeeId === to_employee.id;
  const isDisabledClapButton = isMyCard || isClappedOver20;

  return (
    <Container>
      <CardHeader>
        <UserIcon
          size={40}
          id={from_employee.id}
          avatar={from_employee.avatar}
          real_name={from_employee.real_name}
        />
        <DisplayName href={`/employees/${from_employee.id}`}>
          {from_employee.display_name}
        </DisplayName>
        <Arrow>
          <Image src={TipArrow} width={20} height={16} alt="→" />
        </Arrow>
        <UserIcon
          size={64}
          id={to_employee.id}
          avatar={to_employee.avatar}
          real_name={to_employee.real_name}
        />
        <NameAndPoint>
          <DisplayName href={`/employees/${to_employee.id}`}>
            {to_employee.display_name}
          </DisplayName>
          <SendPoint>
            <Plus>+</Plus>
            {amount}
          </SendPoint>
        </NameAndPoint>
      </CardHeader>
      {/**
       * @todo 機能追加
       * - メッセージを加工する
       * - クリックすると投稿の詳細画面に遷移する (新機能なので移行期間中は無くてもよい)
       * */}
      <MessageComponent message={message} />
      <CardFooter>
        <DateAndEditWrapper>
          <SendDate>{sendDate}</SendDate>
          {is_editable && (
            <EditButton href={`/cards/${id}/edit`}>Edit</EditButton>
          )}
        </DateAndEditWrapper>
        <ClapWrapper>
          {clap_count > 0 && (
            <>
              <ClapCount>
                <Plus>+</Plus>
                {clap_count}
              </ClapCount>
              {claps && <ClappedEmployeeList claps={claps} />}
            </>
          )}
          <ClapButton
            onClick={handleClickClapButton}
            onMouseDown={handleMouseDownClapButton}
            onMouseUp={handleMouseUpClapButton}
            disabled={isDisabledClapButton}
          >
            <Image
              src={isClapped ? ClapIconActive : ClapIconDefault}
              alt="Clap"
              width={32}
              height={32}
            />
            {isDisabledClapButton && isMyCard && (
              <Tooltip>
                <p>自身が送信/受信した投稿は</p>
                <p>拍手ができません</p>
              </Tooltip>
            )}
            {isDisabledClapButton && isClappedOver20 && (
              <Tooltip>
                <p>1つのカードに対して</p>
                <p>送信できる拍手は20回までです</p>
              </Tooltip>
            )}
          </ClapButton>
        </ClapWrapper>
      </CardFooter>
    </Container>
  );
};
