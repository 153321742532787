import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.base.bg.main};
  border: 1px solid ${({ theme }) => theme.colors.base.border.main};
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
`;

const CardHeader = styled.div`
  display: grid;
  /* 左からそれぞれ, 送った従業員のアイコン, 送った従業員の名前, 矢印, 貰った従業員のアイコン, 貰った従業員の名前 の幅 */
  grid-template-columns: 40px 0.8fr 40px 64px 1fr;
  max-width: 100%;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
`;

const IconPlaceholder = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.base.border.main};
`;

const TextPlaceholder = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.base.border.main};
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 24px;
  align-items: center;
  width: 100%;
`;

const CardPlaceholder: FC = () => {
  return (
    <Container>
      <CardHeader>
        <IconPlaceholder size={40} />
        <TextPlaceholder width={100} />
        <div />
        <IconPlaceholder size={64} />
        <TextPlaceholder width={100} />
      </CardHeader>
      <Message>
        <TextPlaceholder />
        <TextPlaceholder />
      </Message>
      <CardFooter>
        <TextPlaceholder width={100} />
        <IconPlaceholder size={48} />
      </CardFooter>
    </Container>
  );
};

export const CardListPlaceholder: FC = () => {
  return (
    <List>
      {Array.from({ length: 10 }).map((_, index) => (
        <CardPlaceholder key={index} />
      ))}
    </List>
  );
};
