import { FC } from 'react';
import { WebCardSchema } from 'src/__generated__/@types';
import styled from 'styled-components';
import { UserIcon } from '../UserIcon';

type Props = {
  claps: Exclude<WebCardSchema['claps'], undefined>;
};

const List = styled.ul`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  gap: 4px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`;

/**
 * 拍手した人のアイコンを先頭から10人まで一覧表示する。
 * 10人以上は ... で省略する。
 */
export const ClappedEmployeeList: FC<Props> = ({ claps }) => {
  const clappedEmployee = claps.slice(0, 10);

  return (
    <List>
      {clappedEmployee.map(({ employee: { id, real_name, avatar } }) => {
        return <UserIcon key={id} size={24} {...{ id, real_name, avatar }} />;
      })}
      {claps.length > 10 && <p>...</p>}
    </List>
  );
};
