import { SecondaryButton } from '@components/ui';
import { FC } from 'react';
import { WebCardsSchema, WebMeSchema } from 'src/__generated__/@types';
import styled from 'styled-components';
import { KeyedMutator } from 'swr';
import { Card } from '../Card';

type FetchedCardsData = {
  cards: WebCardsSchema;
  has_next: boolean;
}[];

type Props = {
  fetchedCardsData: FetchedCardsData;
  currentEmployeeId: WebMeSchema['id'];
  currentEmployeeMutate: KeyedMutator<WebMeSchema>;
  cardsMutate: KeyedMutator<FetchedCardsData>;
  handleClickLoadButton: () => void;
  isLoadingMore: boolean;
  isReachingEnd: boolean;
};

const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 24px;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

/**
 * 投稿一覧コンポーネント
 * @param keyForFetching -
 *  tenant_id と router.asPath を結合した文字列を
 *  useSWRInfinite の getKey 関数に渡す key として使用する
 * @param fetcher - 取得したい投稿の情報がページによって異なるため、外から受け取る
 * @param currentEmployeeMutate -
 *  拍手時にログインユーザー情報 (送れるポイント) を更新する関数を
 *  useCurrentEmployee から受け取る
 */
export const CardList: FC<Props> = ({
  fetchedCardsData,
  currentEmployeeId,
  currentEmployeeMutate,
  cardsMutate,
  handleClickLoadButton,
  isLoadingMore,
  isReachingEnd,
}) => {
  return (
    <>
      <List>
        {fetchedCardsData.map((d) => {
          return d.cards.map((card) => {
            return (
              <Card
                key={card.id}
                {...card}
                cardsMutate={cardsMutate}
                currentEmployeeMutate={currentEmployeeMutate}
                currentEmployeeId={currentEmployeeId}
              />
            );
          });
        })}
      </List>
      {!isReachingEnd && (
        <ButtonWrapper>
          <SecondaryButton
            disabled={isLoadingMore}
            onClick={handleClickLoadButton}
          >
            {isLoadingMore ? '読み込み中' : 'もっと読み込む'}
          </SecondaryButton>
        </ButtonWrapper>
      )}
    </>
  );
};
